<template>
  <el-dialog
    v-model="winShow"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :title="title"
    width="900px"
    @close="onClose">
    <div class="form">
      <!-- 步骤条 -->
      <el-steps v-if="!edit_id" :active="activeStep" align-center>
        <el-step :title="t('user.create_sale_user')" />
        <el-step :title="t('enterprise.authorize_product')" />
      </el-steps>
      <!-- 占位高度 -->
      <div class="zhanwei"></div>
      <!-- 创建销售 -->
      <div v-show="activeStep === 1">
        <create-user
          ref="createSale"
          addType="SalesMan"
          :winShow="winShow"
          @onClose="onClose"
          @nextStep="nextStep"
          @getList="() => emit('getList')" />
      </div>
      <div v-show="activeStep === 2">
        <createSales-product
          ref="createSalesProduct"
          :winShow="winShow"
          :activeStep="activeStep" />
      </div>
      <div v-if="activeStep === 2" class="dialog-footer">
        <el-button @click="prevStep" type="primary">{{ t('form.prev') }}</el-button>
        <el-button :loading="submitLoading" @click="submitForm" type="primary">{{ t('form.submit') }}</el-button>
        <el-button @click="onClose">{{ t('form.cancel') }}</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script setup>
import { ref, inject, computed, reactive, nextTick } from 'vue'
import CreateSalesProduct from './createSalesProduct.vue'
import { productManagement, userManagement } from '@/utils/api'
import { ElMessage } from 'element-plus'

const t = inject('t')
const activeStep = ref(1) // 当前步骤
const edit_id = ref(null) // 编辑
const winShow = ref(false) // 弹框显示
const createSale = ref() // 创建销售
const createSalesProduct = ref() // 创建销售产品
const submitLoading = ref(false)
const emit = defineEmits(['getList'])
const formData = reactive({
  userData: {}
})
const title = computed(() => {
  return !!edit_id.value ? t('user.modify_sale') : t('user.create_sale')
})
// 打开弹框
const openWin = async (id = null) => {
  edit_id.value = id
  winShow.value = true
  await nextTick()
  if (edit_id.value) createSale.value.getUserDetail(edit_id.value)
}
// 下一步
const nextStep = (data) => {
  formData.userData = data
  activeStep.value++
}
// 上一步
const prevStep = () => {
  activeStep.value--
}
// 关闭弹框
const onClose = () => {
  if (createSale.value) createSale.value.clearForm()
  if (createSalesProduct.value) createSalesProduct.value.clearForm()
  winShow.value = false
}
// 提交表单
const submitForm = async () => {
  if (createSalesProduct.value) {
    submitLoading.value = true
    try {
      const user = await userManagement.roleUserCreate(formData.userData) // 创建用户请求
      const res = await createSalesProduct.value.addProductRequest()
      const proArr = res.map(item => {
        return productManagement.SalesProductCreate({
          product_id: item,
          user_id: user.id
        })
      })
      await Promise.all(proArr) // 等待所有请求完成
      ElMessage.success(t('table.add_success'))
      onClose()
      emit('getList')
      submitLoading.value = false
    } catch (error) {
      submitLoading.value = false
    }
  }
}
defineExpose({
  openWin
})
</script>
