<template>
  <el-dialog v-model="winShow" width="900px" :close-on-click-modal="false" :close-on-press-escape="false"
    :title="t('enterprise.authorize_product')">
    <div class="form" style="width:100% !important;">
      <div>{{ t('user.sales_name') }}：{{ salesInfo.sales || '-' }}</div>
      <div class="products_wrap">
        <div class="product_label">{{ t('routes.sales_product') }}:</div>
        <div class="product_list">
          <!-- 选择产品 -->
          <div v-if="openProSelect" class="product_select">
            <el-select v-model="product_id" filterable :placeholder="t('form.selectPlaceholder')" style="flex: 1;"
              class="product_item">
              <el-option v-for="(item, index) in selectProList" @change="checkExist" :key="index" :label="item.label"
                :value="item.value" />
            </el-select>
            <el-button class="del_pro" @click="creatPro" type="text">{{ t('form.save') }}</el-button>
            <el-button @click="cancelPro" link type="primary">{{ t('form.cancel') }}</el-button>
          </div>
          <div class="addpro">
            <el-button type="primary" :disabled="openProSelect" @click="openProSelect = true">{{
    t('product.add_product') }}</el-button>
            <el-button type="primary" plain v-if="tableInfo.data.length" @click="deleteData">{{ t('form.delete')
              }}</el-button>
          </div>
        </div>
      </div>
      <div class="products_wraps" v-if="tableInfo.data.length">
        <div class="product_label">{{ t('routes.sales_product') }}:</div>
        <div class="product_list">
          <!-- 销售产品 -->
          <custem-table :tableInfo="tableInfo" :tableLoading="tableLoading" @onSelectData="onSelectData" @getProduct="getProduct" />
        </div>
      </div>
    </div>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="closeWin">{{ t('form.close') }}</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, reactive, nextTick, inject } from 'vue'
import { productManagement } from '@/utils/api'
import { ElMessage } from 'element-plus'

const t = inject('t')
const winShow = ref(false)
const salesInfo = ref({
  sales: '', // 销售名称
  id: '' // 销售id
})
const product_id = ref('') // 选择产品id
const selectProList = ref([]) // 选择框产品列表
const openProSelect = ref(false); // 添加产品选择框
const delList = ref([]); // 删除数据id列表
const tableLoading = ref(false)

// 打开弹框
const openWin = (id, name) => {
  winShow.value = true
  salesInfo.value.sales = name
  salesInfo.value.id = id
  getProduct()
  getProductList()
}

// 关闭弹框
const closeWin = () => {
  winShow.value = false
  salesInfo.value.sales = ''
  salesInfo.value.id = ''
  delList.value = []
}

// 查看产品
const getProduct = async (search = {}) => {
  tableLoading.value = true
  try {
    const res = await productManagement.SalesProductList({
      ...search,
      filter_user_id: salesInfo.value.id
    })
    tableInfo.data = res.items
    tableInfo.totalPage = res.total
    tableInfo.pageSize = res.per_page
    tableLoading.value = false
  } catch (error) {
    tableLoading.value = false
  }
}

// 取消添加
const cancelPro = () => {
  product_id.value = ''
  openProSelect.value = false
}

// 检测销售下是否存在选中产品
const checkExist = () => {
  if (!!!product_id.value) return
  if (!!!salesInfo.value.id) return
  return new Promise((resolve, reject) => {
    productManagement.SalesProductList({
      filter_product_id: product_id.value,
      filter_user_id: salesInfo.value.id
    }).then(res => {
      if (!res.items.length) {
        resolve(1)
      }
      ElMessage.warning(t('user.exist_sale_product'))
      resolve(0)
    })
  })
}

// 获取产品列表
const getProductList = () => {
  productManagement.ProductList({
    is_paginate: false
  }).then(res => {
    const arr = []
    res.items.forEach(item => {
      arr.push({
        label: `${item.deployment.name}/${item.category.name}/${item.name}`,
        value: item.id
      })
    });
    selectProList.value = arr
  })
}

// 创建产品
const creatPro = async () => {
  if (!!!product_id) {
    ElMessage.warning(t('enterprise.select_product'))
    return
  }
  const res = await checkExist() // 检测销售下是否存在选中产品
  if (res) {
    productManagement.SalesProductCreate({
      product_id: product_id.value,
      user_id: salesInfo.value.id
    }).then(() => {
      ElMessage.success(t('table.add_success'))
      getProduct(salesInfo.value.id)
      cancelPro()
    })
  }
}

// ================== 删除相关 ==================
// 执行删除
const deleteData = async () => {
  if (!!!delList.value.length) {
    ElMessage.warning(t('table.select_del'))
    return
  }
  let arr = []
  delList.value.forEach(item => {
    arr.push(
      // 批量提交
      productManagement.SalesProductDelete(item)
    )
  })
  await nextTick()
  Promise.all(arr).then(async () => {
    ElMessage.success(t('table.del_success'))
    getProduct(salesInfo.value.id)
    delList.value = []
  }).catch(() => {
    ElMessage.success(t('table.del_failed'))
  })
}

// 行内删除
const singleDel = (data) => {
  delList.value = []
  delList.value.push(data.id)
  deleteData() // 执行删除
}

// 表格多选事件
const onSelectData = (data) => {
  delList.value = data.map(item => {
    return item.id
  })
}

// ================== 表格相关 ==================
const tableInfo = reactive({
  selection: true, // 选择框列
  header: [
    { // 产品大类
      key: 'deployment',
      label: t('product.product_category'),
      width: '160px',
      custem: (data) => {
        return data.product ? data.product.deployment.name : '-'
      }
    },
    { // 产品类型
      key: 'category',
      label: t('product.product_type'),
      width: '140px',
      custem: (data) => {
        return data.product ? data.product.category.name : '-'
      }
    },
    { // 产品名称
      key: 'product_name',
      label: t('product.product_name'),
      custem: (data) => {
        return data.product ? data.product.name : '-'
      }
    },
    { // 产品编码
      key: 'code',
      label: t('product.product_code'),
      custem: (data) => {
        return data.product ? data.product.code : '-'
      }
    },
    {
      key: 'login_at',
      label: t('table.action'),
      width: '80px',
      fixed: 'right',
      handleFun: [
        {
          name: t('form.delete'),
          type: 'delete',
          content: t('form.sure_del'),
          fn: singleDel
        }
      ]
    }
  ],
  data: []
})

defineExpose({
  openWin: openWin
})

</script>

<style lang="scss">
.products_wrap {
  display: flex;
  align-items: flex-start;
}

.product_label {
  margin-top: 20px;
  min-width: 62px;
}

.product_list {
  margin-left: 10px;
  flex: 1;
  margin-top: 15px;

  .products_item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .product_item {
      background: #ededed;
      padding-left: 10px;
      box-sizing: border-box;
      line-height: 32px;
      flex: 1;
    }
  }
}

.del_pro {
  cursor: pointer;
  width: 50px;
  min-width: 50px;
}

.product_select {
  width: 60%;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.nodata {
  margin-top: 20px;
  margin-left: 10px;
}

.addpro {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
</style>