<template>
  <div class="main">
    <!-- 搜索栏 -->
    <ListToolBar @getList="getList" @onDelete="onDelete" @onCreate="onCreate" />
    <!-- 运营管理 -->
    <custem-table ref="table" :tableInfo="tableInfo" :tableLoading="tableLoading" @onSelectData="onSelectData" @getList="pageChange"></custem-table>
    <!-- 表单 -->
    <form-data ref="formRef" @getList="getList"></form-data>
    <!-- 查看产品 -->
    <ShowProduct ref="show_product" />
    <!-- 转移企业 -->
    <Transfer ref="tansferEnterprise" />
  </div>
</template>
<script setup>

import ListToolBar from './components/ListToolBar'
import FormData from './components/Form.vue'
import ShowProduct from './components/ShowPro'
import Transfer from './components/transfer'
import { userManagement } from '@/utils/api'
import { onMounted, reactive, ref, inject } from 'vue'
import { ElMessage } from "element-plus"

const tableLoading = ref(false)
const t = inject('t')
const table = ref()
const formRef = ref() // 表单对象
const delList = ref([]) // 删除数据
const show_product = ref()
const tansferEnterprise = ref()
const searchData = ref({
  page: 1,
  filter_role_alias: 'SalesMan'
})

// 删除
const deleteData = async (row) => {
  const { id } = row
  if (!!!id) return
  await userManagement.roleUserDelete(id)
  ElMessage.success(t('table.del_success'))
  delList.value = []
  tableInfo.data = tableInfo.data.filter((ele) => ele.id !== id)
  getList()

}

// 编辑
const editData = async (row) => {
  const { id } = row
  if (!!!id) return
  if (formRef.value) formRef.value.openWin(id)
}

// 创建
const onCreate = () => {
  if (!formRef.value) return
  formRef.value.openWin()
}

// 多选删除
const onDelete = () => {
  if (!!!delList.value.length) {
    ElMessage.warning(t('table.select_del'))
    return
  }
  delList.value.forEach(item => {
    deleteData(item)
  })
}
// 绑定钉钉
const bindDingTalk = async (row) => {
  const { id } = row
  if (!!!id) return
  const res = await userManagement.roleUserBind(id)
  if (res.msg === 'ok') {
    ElMessage.success(t('table.bind_success'))
    getList()
  }
}

// 接收多选数据
const onSelectData = (data) => {
  delList.value = data
}

// 查看产品
const showProduct = (data) => {
  if (!!!show_product.value) return
  show_product.value.openWin(data.id, data.name)
}

// 转移企业
const transfer = (data) => {
  if(!!!tansferEnterprise.value) return
  tansferEnterprise.value.openWin(data)
}

// 表格信息
const tableInfo = reactive({
  selection: true, // 选择框列
  header: [
    { // 用户登陆名
      key: 'name',
      label: t('user.username'),
      minWidth: '280px'
    },
    { // 昵称
      key: 'nick_name',
      label: t('user.nick_name'),
      minWidth: '320px'
    },
    { // 联系人手机号
      key: 'liaison_phone',
      label: t('user.liaison_phone'),
      minWidth: '320px',
      custem: (data) => {
        return data.params ? data.params.liaison_phone : '-'
      }
    },
    { // 钉钉手机号
      key: 'phone',
      label: t('user.dingtalk_phone'),
      minWidth: '320px',
      custem: (data) => {
        return data.phone || '-'
      }
    },
    { // 注册日期
      key: 'created_at',
      label: t('table.registration_time'),
      minWidth: '180px',
      sortable: true,
      sortBy: (data) => {
        return data.created_at
      }
    },
    { // 最后登录
      key: 'login_at',
      label: t('table.last_login_time'),
      width: '180px',
      sortable: true,
      sortBy: (data) => {
        return data.created_at
      }
    },
    {
      key: 'login_at',
      label: t('table.action'),
      width: localStorage.getItem('language') === 'en' ? '490px' : '290px',
      fixed: 'right',
      handleFun: [
        { // 授权产品
          name: t('enterprise.authorize_product'),
          fn: showProduct
        },
        { // 转移企业
          name: t('enterprise.transfer_enterprise'),
          fn: transfer
        },
        { // 绑定钉钉
          name: t('enterprise.bind_dingtalk'),
          fn: bindDingTalk,
          disabled: (data) => {
            return data.oauth && data.oauth.length > 0
          }
        },
        {
          name: t('table.modify'),
          fn: editData
        },
        {
          name: t('form.delete'),
          type: 'delete',
          content: t('form.sure_del'),
          fn: deleteData
        }
      ]
    }
  ]
})

// 分页触发改变
const pageChange = (current) => {
  searchData.value.page = current.page
  getList({ ...searchData.value })
}
const getList = async (search = null) => {
  tableLoading.value = true
  searchData.value = search ? { ...search } : searchData.value
  table.value.resetCurrent(searchData.value.page)
  try {
    const res = await userManagement.roleUserList({
      ...searchData.value
    })
    tableInfo.data = res.items
    tableInfo.totalPage = res.total
    tableInfo.pageSize = res.per_page
    tableLoading.value = false
  } catch (error) {
    tableLoading.value = false
  }
}

onMounted(() => {
  getList()
})
</script>