<template>
  <!-- 转入企业 -->
  <div class="transfer-wrap">
    <!-- 步骤条 -->
    <el-steps :active="activeStep" align-center>
      <el-step :title="t('enterprise.entry_enterprise')" />
      <el-step :title="t('enterprise.entry_enterprise_user')" />
    </el-steps>
    <div class="tabs" v-if="activeStep === 1">
      <div></div>
      <div class="search_item">
        <el-input
          v-model="searchData.search"
          :prefix-icon="Search"
          :placeholder="t('enterprise.name')"
          clearable
          @input.native="doSearch"
          class="search-input" />
      </div>
    </div>
    <div class="transfer-table">
      <transfer-table ref="transferTableRef" @pushData="pushData" v-if="activeStep === 1" />
      <enterprise-user v-else :enterprisInfo="selectdEnterprise" :salesUserId="salesUserId"
        @pushStepData="pushStepData" />
    </div>
    <!-- 转入 -->
    <div class="dialog-footer">
      <el-button @click="prev" type="primary" v-if="activeStep > 1">
        {{ t('form.prev') }}
      </el-button>
      <el-button @click="next" type="primary" v-if="activeStep === 1" :disabled="disabledNextBtn">
        {{ t('form.next') }}
      </el-button>
      <el-button @click="onSubmit" type="primary" v-else :disabled="disabledSureBtn">{{ t('form.submit') }}</el-button>
      <el-button @click="emit('closeWin')">{{ t('form.close') }}</el-button>
    </div>
  </div>
</template>

<script setup>
import { watch, ref, nextTick, inject } from 'vue'
import TransferTable from './transferTable.vue'
import EnterpriseUser from './enterprise_user.vue'
import { Search } from '@element-plus/icons-vue'
import { userManagement, enterpriseManagement } from '@/utils/api'
import { ElMessage } from 'element-plus'

const t = inject('t')
const activeStep = ref(1)
const disabledSureBtn = ref(true)
const disabledNextBtn = ref(true)
const emit = defineEmits('closeWin')
const tansferdata = ref([])
const transferTableRef = ref()
const searchData = ref({})
const selectdEnterprise = ref({})
const props = defineProps({
  salesUserId: {
    type: String,
    default: ''
  }
})

const doSearch = async () => {
  await nextTick()
  transferTableRef.value.getEnterpriseList({
    ...searchData.value,
    filter_screen_sale_id: props.salesUserId
  }, 'in')
}

const next = () => {
  activeStep.value = 2
  disabledSureBtn.value = true
}

const prev = async () => {
  disabledNextBtn.value = true
  activeStep.value = 1
  await nextTick()
  transferTableRef.value.getEnterpriseList({
    ...searchData.value,
    filter_screen_sale_id: props.salesUserId
  }, 'in')
}

// 企业用户保存
const pushStepData = (data) => {
  if (data.length) {
    disabledSureBtn.value = false
    tansferdata.value = data
  } else {
    disabledSureBtn.value = true
  }
}

// 提交
const onSubmit = async () => {
  requetsAll()
}

const requetsAll = () => {
  // 编辑
  enterpriseManagement.EnterpriseUpdate(selectdEnterprise.value.id, {
    sales_user_id: props.salesUserId
  }).then(async res => {
    let arr = [];
    tansferdata.value.forEach(item => {
      let obj = {}
      obj = {
        parent_id: item.parent_id
      }
      arr.push(
        // 新增
        userManagement.roleUserUpdate(item.id, obj)
      )
    })
    await nextTick()
    Promise.all(arr).then(() => {
      ElMessage.success(t('table.transfer_successful'))
      emit('closeWin')
    })
  })
}

watch(() => props.salesUserId, async val => {
  if (val) {
    await nextTick()
    transferTableRef.value.getEnterpriseList({
      ...searchData.value,
      filter_screen_sale_id: val
    }, 'in')
  }
}, {
  immediate: true, deep: true
})


const pushData = (data) => {
  if (data.length == 1) {
    disabledNextBtn.value = false
    selectdEnterprise.value = data[0]
  } else {
    disabledNextBtn.value = true
  }
}

</script>

<style lang="scss" scoped>
@import './common.scss';
</style>