<template>
  <!-- 转出企业 -->
  <div class="transfer-wrap">
    <!-- 步骤条 -->
    <el-steps :active="activeStep" align-center>
      <el-step :title="t('enterprise.exit_enterprise')" />
      <el-step :title="t('enterprise.exit_enterprise_user')" />
    </el-steps>
    <div class="transfer-table" style="padding: 16px 0;">
      <div v-if="activeStep === 1">
        <el-table
          class="tables table-wraps"
          :header-cell-style="{
            background: '#F2F2F2',
            color: '#525252',
            fontSize: '13px',
            height: '30px'
          }" stripe
          ref="table"
          border v-loading="loading" style="height: calc(100% - 90px);" height="100%" :data="tableInfo">
          <el-table-column :label="t('enterprise.name')" min-width="name" prop="name" />
          <el-table-column :label="t('user.sales')" width="400px">
            <template #default="scope">
              <el-select v-model="scope.row.parent_id" filterable :placeholder="t('user.sales')">
                <el-option v-for="(sale, index) in salesList" :key="index" :label="sale.label" :value="sale.value" />
              </el-select>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <enterprise-user v-else :enterprisInfo="enterpriseInfo" :salesUserId="salesUserId" @pushStepData="pushStepData" />
    </div>
    <!-- 转入 -->
    <div class="dialog-footer">
      <el-button @click="prev" type="primary" v-if="activeStep > 1">
        {{ t('form.prev') }}
      </el-button>
      <el-button @click="next" type="primary" v-if="activeStep === 1">
        {{ t('form.next') }}
      </el-button>
      <el-button @click="onSubmit" type="primary" v-else :disabled="disabledSureBtn">{{ t('form.submit') }}</el-button>
      <el-button @click="emit('closeWin')">{{ t('form.close') }}</el-button>
    </div>
  </div>
</template>

<script setup>
import EnterpriseUser from './enterprise_user.vue'
import { ref, nextTick, watch, inject } from 'vue'
import { getUserList } from '@/utils/common'
import { ElMessage } from 'element-plus'
import { enterpriseManagement, userManagement } from '@/utils/api'

const t = inject('t')
const emit = defineEmits(['closeWin'])
const tansferdata = ref([])
const props = defineProps({
  salesUserId: {
    type: String,
    default: ''
  },
  enterpriseInfo: {
    type: Object,
    default: () => {
      return {}
    }
  }
})
const salesList = ref([])
const loading = ref(true)
const activeStep = ref(1)
const disabledSureBtn = ref(true)
const tableInfo = ref()

watch(() => props.enterpriseInfo, async val => {
  await nextTick()
  if (val) {
    tableInfo.value = [val]
    loading.value = false
  }
}, { immediate: true, deep: true })

watch(() => props.salesUserId, async val => {
  salesList.value = await getUserList({ filter_role_alias: 'SalesMan' })
  tableInfo.value[0].parent_id = props.salesUserId
}, { immediate: true, deep: true })

const next = async () => {
  activeStep.value = 2
}

const prev = async () => {
  activeStep.value = 1
  await nextTick()
}

// 企业用户保存
const pushStepData = (data) => {
  if (data.length) {
    disabledSureBtn.value = false
    tansferdata.value = data
  } else {
    disabledSureBtn.value = true
  }
}

// 提交
const onSubmit = async () => {
  requetsAll()
}

const requetsAll = () => {
  // 编辑
  enterpriseManagement.EnterpriseUpdate(props.enterpriseInfo.id, {
    sales_user_id: tableInfo.value[0].parent_id
  }).then(async res => {
    let arr = [];
    tansferdata.value.forEach(item => {
      let obj = {}
      obj = {
        parent_id: item.parent_id
      }
      arr.push(
        // 新增
        userManagement.roleUserUpdate(item.id, obj)
      )
    })
    await nextTick()
    Promise.all(arr).then(() => {
      ElMessage.success(t('table.transfer_out_successful'))
      emit('closeWin')
    })
  })
}


</script>
