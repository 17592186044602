<template>
  <!-- 授权产品 -->
  <div>
    <el-form
      :model="productData"
      :rules="formRules"
      ref="form"
      label-width="150px"
      label-position="right"
      style="width: 500px; margin: auto">
      <el-form-item
        v-for="(item, index) in formProductItems"
        :key="index"
        :label="index === 0 ? t('product.add_product') : ''"
        :prop="item.prop"
        required>
        <el-select
          filterable
          clearable
          v-model="productData[item.prop]"
          :placeholder="t('enterprise.select_product')">
          <el-option
            v-for="(ele, key) in productList"
            :key="ele.value"
            :label="ele.label"
            :value="ele.value">
          </el-option>
        </el-select>
        <span v-if="index > 0" style="margin-left: 10px;cursor: pointer;" @click="delProduct(item.prop, index)">
          <el-icon>
            <Delete />
          </el-icon>
        </span>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="addProduct">{{ t('form.add') }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script setup>
import { ref,  watch, inject, nextTick } from 'vue'
import { getUserProduct } from '@/utils/common'

const t = inject('t')
const productList = ref([])
const form = ref()
const props = defineProps({
  winShow: {
    type: Boolean,
    default: false
  },
  activeStep: {
    type: Number,
    default: 1
  }
})

watch(() => props.winShow, val => {
  if(!!!val) form.value.resetFields()
}, { immediate: true, deep: true })

watch(() => props.activeStep, async val => {
  const res = await getUserProduct()
  productList.value = res
}, { immediate: true, deep: true })

const rules = [{ required: true, message: t('form.emptyErrMsg'), trigger: 'blur' }]
const formProductItems = ref([
  { prop: 'product_id0'}
])
const productData = ref({
  product_id0: ''
})
const formRules = ref({
  product_id0: rules
})
// 继续添加产品
const addProduct = () => {
  const newItem = { prop: `product_id${formProductItems.value.length}` }
  formProductItems.value.push(newItem)
  // 更新校验规则
  formRules.value[newItem.prop] = rules
}

// 删除产品
const delProduct = (item, index) => {
  delete productData.value[item]
  formProductItems.value.splice(index, 1)
}

// 创建产品请求
const addProductRequest = async () => {
  const valid = await form.value.validate() // 使用 await 直接获取验证结果
  if (!valid) return null // 返回 null 或者其他适当的值表示验证失败
  return [...new Set(Object.values(productData.value))]
}
const clearForm = async () => {
  await nextTick()
  if (form.value) form.value.clearForm()
}

defineExpose({
  addProductRequest: addProductRequest,
  clearForm: clearForm
})
</script>