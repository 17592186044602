<template>
  <el-dialog
    v-model="winShow"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :title="transferTitle"
    width="900px">
    <div class="form" style="width:100% !important;" v-if="!!!transferType">
      <div class="sales-info">
        {{ t('user.sales_name') }}：{{ salesInfo.name || '' }}
      </div>
      <div class="steps">
        <div class="tabs">
          <el-button type="primary" @click="transferType = 'in'">{{ t('enterprise.entry_enterprise') }}</el-button>
          <div class="search_item">
            <el-input
              v-model="searchData.search"
              :prefix-icon="Search"
              :placeholder="t('enterprise.name')"
              clearable
              @input.native="doSearch"
              class="search-input" />
          </div>
        </div>
      </div>
      <div class="transfer-table" v-if="transferType != 'out'">
        <TransferTable ref="transferTableRef" @transferOut="transferOutData" />
      </div>
    </div>
    <div class="form" style="width:100% !important;" v-else>
      <TansferIn v-if="transferType == 'in'" :salesUserId="salesInfo.id" @closeWin="closeWin" />
      <TransferOut v-if="transferType == 'out'" :salesUserId="salesInfo.id" :enterpriseInfo="enterpriseInfo"
        @closeWin="closeWin" />
    </div>
    <template #footer>
      <div class="dialog-footer" v-if="!!!transferType">
        <el-button @click="closeWin">{{ t('form.close') }}</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, computed, nextTick, inject } from 'vue'
import { Search } from '@element-plus/icons-vue'
import TansferIn from './transferIn.vue'
import TransferOut from './transferOut.vue'
import TransferTable from './transferTable.vue'

const t = inject('t')
const searchData = ref({})
const salesInfo = ref({})
const enterpriseInfo = ref({})
const winShow = ref(false)
const transferType = ref('')
const transferTableRef = ref()

// 打开弹框
const openWin = async (data) => {
  winShow.value = true
  salesInfo.value = data
  searchData.value.filter_sales_user_id = salesInfo.value.id
  await nextTick()
  transferTableRef.value.getEnterpriseList(searchData.value)
}

const transferOutData = (data) => {
  transferType.value = 'out'
  enterpriseInfo.value = data
}

// 关闭弹框
const closeWin = () => {
  winShow.value = false
  transferType.value = ''
}

const transferTitle = computed({
  get() {
    if (transferType.value) {
      return transferType.value === 'in' ? t('enterprise.entry_enterprise') : t('enterprise.exit_enterprise')
    }
    return t('enterprise.transfer')
  }
})

const doSearch = () => {
  if (!!!transferTableRef.value) return
  transferTableRef.value.getEnterpriseList(searchData.value)
}

defineExpose({
  openWin: openWin
})

</script>

<style lang="scss" scoped>
@import './common.scss';
</style>