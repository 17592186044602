<template>
  <div class="transfer-table">
    <custem-table :tableInfo="tableInfo" :tableLoading="tableLoading" @onSelectData="onSelectData" @getList="pageChange"></custem-table>
  </div>
</template>

<script setup>
import { reactive, ref, inject } from 'vue'
import { enterpriseManagement } from '@/utils/api'

const t = inject('t')
const transferType = ref()
const emit = defineEmits(['pushData', 'transferOut'])
const tableLoading = ref(false)
// 转出
const transferOut = (data) => {
  emit('transferOut', data)
}

// 选择框change触发
const onSelectData = (data) => {
  emit('pushData', data)
}

// 表格信息
const tableInfo = reactive({
  selection: false, // 选择框列
  header: [
    { // 企业名称
      key: 'name',
      label: t('enterprise.name'),
      minWidth: '280px'
    },
    { // 企业编码
      key: 'code',
      label: t('enterprise.code'),
      minWidth: '320px'
    },
    { // 所属销售
      key: 'sales-name',
      label: t('user.sales'),
      minWidth: '320px',
      custem: (data) => {
        return data.sales ? data.sales.name : '-'
      }
    },
    {
      key: 'login_at',
      label: t('table.action'),
      width: '120px',
      fixed: 'right',
      handleFun: [
        {
          name: t('table.transfer_out'),
          fn: transferOut
        }
      ]
    }
  ]
})

const pageChange = (data) => {
  getEnterpriseList(data, transferType.value)
}

const getEnterpriseList = async (search = {}, type) => {
  transferType.value = type
  if (type == 'in') {
    tableInfo.header[3].hidden = true
    tableInfo.selection = true
  } else {
    tableInfo.header[3].hidden = false
    tableInfo.selection = false
  }
  tableLoading.value = true
  try {
    const res = await enterpriseManagement.EnterpriseList({
      ...search
    })
    tableInfo.data = res.items
    tableInfo.totalPage = res.total
    tableInfo.pageSize = res.per_page
    tableLoading.value = false
  } catch (error) {
    tableLoading.value = false
  }
}

defineExpose({
  getEnterpriseList: getEnterpriseList
})
</script>