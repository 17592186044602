<template>
  <div style="height: 100%; padding-bottom: 16px;">
    <!-- 企业用户 -->
    <div class="tabs">
      <div> {{ t('enterprise.name') }}：{{ enterprisInfo.name || "" }}</div>
      <div class="search_item">
        <el-input
          v-model="search.search"
          @input.native="doSearch"
          :prefix-icon="Search"
          :placeholder="t('role.role')"
          clearable
          class="search-input" />
      </div>
    </div>
    <el-table
      class="tables table-wraps"
      :row-key="getRowKeys"
      :header-cell-style="{
        background: '#F2F2F2',
        color: '#525252',
        fontSize: '13px',
        height: '30px'
      }"
      stripe
      ref="table"
      border
      v-loading="loading"
      @selection-change="handleSelectionChange"
      style="height: calc(100% - 90px);" height="100%" :data="tableInfo.data">
      <el-table-column reserve-selection type="selection" width="40" align="center" />
      <el-table-column label="企业用户名称" min-width="name" prop="name" />
      <el-table-column label="所属销售" width="400px">
        <template #default="scope">
          <el-select v-model="scope.row.parent_id" filterable placeholder="选择所属销售">
            <el-option v-for="(sale, index) in salesList" :key="index" :label="sale.label" :value="sale.value" />
          </el-select>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <Pager :totalPage="tableInfo.totalPage" :pageSize="tableInfo.pageSize" @getList="getList" />
  </div>
</template>

<script setup>

import { userManagement } from '@/utils/api'
import { watch, reactive, ref, nextTick, inject } from 'vue'
import { getUserList } from '@/utils/common'
import { Search } from '@element-plus/icons-vue'

const t = inject('t')
const emit = defineEmits(['pushStepData'])
const salesList = ref([])
const props = defineProps({
  enterprisInfo: {
    type: Object,
    default: () => {
      return {}
    }
  },
  salesUserId: {
    type: String,
    default: ''
  }
})

const search = ref({})
const doSearch = () => {
  getList(props.enterprisInfo.id)
}

watch(() => props.enterprisInfo, async val => {
  await nextTick()
  if (val) {
    salesList.value = await getUserList({ filter_role_alias: 'SalesMan' })
    getList(val.id)
  }
}, {
  immediate: true,
  deep: true
})


const tableInfo = reactive({
  data: [],
  totalPage: 0,
  pageSize: 0
})

// 多选事件
const handleSelectionChange = (data) => {
  emit('pushStepData', data)
}

const getList = (filter_enterprise_id) => {
  userManagement.roleUserList({
    ...search.value,
    filter_enterprise_id: filter_enterprise_id,
    filter_role_alias: 'enterprise'
  }).then(data => {
    tableInfo.data = data.items;
    tableInfo.data.forEach(item => {
      item.parent_id = item.enterprise.sales.id
    })
    tableInfo.totalPage = data.total
    tableInfo.pageSize = data.per_page
  })
}
const getRowKeys = (data) => {
  return data.parent_id
}
</script>

<style lang="scss" scoped>
@import './common.scss';

.tables {
  margin: 16px 0;
}
</style>